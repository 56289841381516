.App {
  width: 100%;
  margin: auto;
  text-align: center;
  color: #fff;
  display: block;
  overflow: hidden;
}

iframe{
  width: 90% !important;
  margin: 2em auto !important;
  max-width: 540px !important;
}



p {
  font-size: 12px;
  color: #fff;
}


/* ===================== HEADER STYLE ===================== */
.header {
  /*background-color: black;*/
  display: flex;
  align-items: center;
  padding: 0 1em;
  position: fixed;
  z-index: 1000;
  width: 100%;
  }

.header img {
  width: 80px;
  padding-top: .2em;
}
/* ======================================================== */

/* ================== LANDING PAGE STYLE ================== */
.landing-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100% !important;
}



#app-objective-label {
  position: absolute;
  color: #fff;
  padding: .3em .5em;
  border-radius: 10px;
  letter-spacing: 5px;
  z-index: 2;
  top: 45%;
  font-size:xx-large;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Mono";
  font-weight: bold;
  text-align: center;
}

#iphone-grafic{
  position: relative;
  width: 200px;
}

.download-info-area{
  position: absolute;
  text-align: center;
  justify-content: center;
  left: 50%;
  top: 43%;
}
.download-info-area img {
  width: 150px;
}
#see-who-is-going-label{
  font-weight: bold;
  font-size: larger;
  font-family: "Roboto Mono";
  letter-spacing: 2px;
  color: rgb(247 191 46);
}
#ios-download-button{
  width: 100;
  padding-top: 2em;
}
#ios-download-button  :hover{
  cursor: pointer;
}
#download-barcode{
  width: 100;

}
#download-barcode img {
  border-radius: 1em;
}

/*================================================*/


/* ============= REVIEWS STYLES ==================*/

/* Ensure swiper container is relative */
.swiper-container {
  position: relative;
}

/* Align navigation buttons */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10; /* Ensure navigation buttons are above other content */
}

/* Position left button */
.swiper-button-prev {
  left: 10px; /* Adjust as needed */
}

/* Position right button */
.swiper-button-next {
  right: 10px; /* Adjust as needed */
}

.card {
  position: relative;
  display: grid;
  grid-template-rows: 1.5fr 1fr;
  background-color: rgb(77 114 190);
  padding: 1em;
  overflow: hidden;
  transform: skewX(-10deg); /* Adjust the skew angle as needed */
}

/* Reset the skew for the card content */
.card > * {
  transform: skewX(10deg); /* Adjust the skew angle to match the card */
}

.review-area {
  height: 50vh;
  margin-top: 10em;
}

.quote {
  padding: 1em;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  align-self: baseline;
}

.profile-image {}

.profile-image img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
}

.profile-text {
  text-align: left;
}

.profile-name {
  font-weight: bold;
}

.profile-school {}

/* ===============================================*/

/* ============== ICONLIST STYLES ================ */
.icon-list-grid-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 50vh;
  gap: 2em;
  padding: 2em;
  margin-top: 5em;
}

.icon-list-grid-container :nth-child(2){
  margin-bottom: 3em;
}

.icon-list-grid-container :nth-child(1){
  margin-top: .5em;
}
.icon-list-grid-container :nth-child(3){
  margin-top: .5em;
}

/*list-item-styles*/
.list-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}
.list-item-icon{

}

.list-item-text-area{

}
.list-item-heading{
  color: #598BD8;
}
.list-item-text{
  color: #F9FCFF;
}
/********************/



/*=========================================*/



/* ============== Phone Container STYLES =========== */
.example-screens-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1em;
  max-width: 80%;
  margin: 2em auto;
}

.phone-container {
  position: relative; /* Add position relative for containing absolutely positioned elements */
  transition: transform 0.3s ease-in-out; /* Smoother transition */
  padding: .5em;
  display: flex;
  flex-direction: column;
}




.phone-container-img img {
  width: 100%; /* Adjust image width to fit container */
}

.phone-container-text-area {
  top: 0;
  display:none;
}

.phone-container-number{
  margin-bottom: 5px;
  display: none;
}


.phone-container-text-area-heading {
  color: #598BD8; 
  margin: 0;
}

.phone-container-text-area-text {
  margin: 0;
  color: #fff
}

/* =============================================== */


/* ============ Marketing Video Styles =========== */
.marketing-video-area {
  margin: auto;
  height: 100vh;
  position: relative;
  
}

.marketing-video {
  height: 100%;
  filter: brightness(115%) hue-rotate(-4deg) saturate(100%);
}

/* =============================================== */ 



/* ================  Impressum  ==================*/

.impressum {
  
}


/* ===============================================*/





/* For mobile devices */
@media only screen and (max-width: 767px) {
  .App{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }

  #title {
    padding-bottom: .5em;
    padding: 0 .5em;
  }

  /* ===================== HEADER STYLE ===================== */
.header {
  /*background-color: black;*/
  text-align: center;
  padding: 0;
  width: 100%;
  }

.header img {
  width: 40px;
  padding-left: 4px;
}
/* ======================================================== */

/* ================== LANDING PAGE STYLE ================== */
.landing-page {
  width: 95% !important;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#iphone-grafic{
  width: 50%;
}

#app-objective-label {
  font-size: 18px;
}


.download-info-area{
  position: absolute;
  top: 100vh;
  left: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-bottom: 2em;
}
.download-info-area img {
  width: 80%;
  margin: auto;
}
#see-who-is-going-label{
  font-weight: bold;
  font-size: larger;
  font-family: "Roboto Mono";
  letter-spacing: 2px;
  color: rgb(247 191 46);
}
#ios-download-button{
  padding-top: 2em;
}
#ios-download-button img :hover{
  cursor: pointer;
}
#download-barcode{
  display: none;
}
#download-barcode img {
  display: none;
}

/*================================================*/

/*===========REVIEW STYLES============================*/
/* Align navigation buttons */
.swiper-button-prev,
.swiper-button-next {
  display: none;
}

/* Position left button */
.swiper-button-prev {
  display: none;
}

/* Position right button */
.swiper-button-next {
  display: none;
}

.review-area {
  height: 10vh;
  padding-top: 10em;
  margin: 0 auto;
  margin-top: 6em;
  width: 90%;
}

.card {
  grid-template-rows: 2fr 1fr;
}


.profile-image {
  padding-left: 1em;
}

.profile-text{
  font-size: smaller;
}

.quote {
  padding: 1em 2em;
}

/*=================================================*/

/* ============== ICONLIST STYLES ================ */
.icon-list-grid-container{
  display: grid;
  grid-template-columns: 1fr;
  height: 50vh;
  gap: 0em;
  padding: 4em 2em;
  margin-top: 10em;
  height: 100vh;  
}

.icon-list-grid-container :nth-child(2){
  margin-bottom: 0;
}


/*list-item-styles*/
.list-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}
.list-item-icon{

}

.list-item-text-area{

}
.list-item-heading{
  color: #598BD8;

}
.list-item-text{
  color: #F9FCFF;
  font-size: 14px;
}
/********************/

/*=========================================*/


/* ============== Phone Container STYLES =========== */
.example-screens-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 1fr);
  width: 100%;
  margin: auto;
}



.phone-container-img img {
  width: 60%; /* Adjust image width to fit container */
}

.phone-container-text-area {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.phone-container-text-area-heading {
  color: #598BD8; 
  margin: 0;
}

.phone-container-text-area-text {
  margin: 1em;
  color: #fff
}

/* =============================================== */

/* ============ Marketing Video Styles =========== */


.marketing-video-area {
  height: 100vh;
  width: 100%;
  margin:auto;
  margin-top: 5em;
}


.marketing-video {
  filter: brightness(115%) hue-rotate(-4deg) saturate(100%);
  margin: auto;
  width: 90%;
}

/* =============================================== */ 
 
}





/* For tablets and larger devices */
@media only screen and (min-width: 768px) {
  /* Add styles specific to tablets and larger devices */
  #title {
    padding-bottom: 0;
  }

  /* ================== LANDING PAGE STYLE ================== */
.landing-page {
  width: 80% !important;
  margin: auto;
}
#ios-download-button img :hover{
  cursor: pointer;
}
/*================================================*/

/*===========REVIEW STYLES============================*/

.profile-image {
  padding-left: 1em;
}

.profile-text{
  font-size: smaller;
}

/*=================================================*/

/* ============== Phone Container STYLES =========== */
.example-screens-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0;
  max-width: 100%;
  margin: 2em auto;
}

.phone-container {
  position: relative;
  transition: transform 0.3s ease-in-out; /* Smoother transition */
  padding: .5em;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-top: 0;
}

.phone-container-img img {
  padding-top: 2%;
  padding-bottom: 20%;
  transition: transform 0.3s ease-in-out;
}

.phone-container:hover {
  transform: translateY(5px); /* Move the card slightly upwards on hover */
}

.phone-container-text-area {
  display: none; /* Initially hide the text area */
  opacity: 0; /* Initially set opacity to 0 */
  transition: opacity 1s ease-in-out; /* Fade-in transition */
  padding-bottom: 5px;
}

.phone-container:hover .phone-container-text-area {
  display: block; /* Show the text area on hover */
  opacity: 1; /* Fade-in effect */
  position: relative;
  margin-top: 1em;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  transform: translateY(-70);
}



/* =============================================== */

}

/* For desktops and larger devices */
@media only screen and (min-width: 1024px) {
  /* Add styles specific to desktops and larger devices */
  #title {
    padding-bottom: 0;
  }

  #ios-download-button img :hover{
    cursor: pointer;
  }

  /* ============== Phone Container STYLES =========== */
  .example-screens-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0em;
    max-width: 90%;
    margin: auto;
    padding: 2em 0;
  }
  
  .phone-container {
    position: relative;
    transition: transform 0.3s ease-in-out; /* Smoother transition */
    padding: .5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .phone-container:hover {
    transform: translateY(-10px); /* Move the card slightly upwards on hover */
  }
  
  .phone-container:hover .phone-container-text-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding-top: 0;
    left: 0;
    width: 100%;
    transform: translateY(0); /* Move text area up on hover */
    transition: transform 0.3s ease-in-out;
  }
  
  .phone-container-img img {
    width: 100%; /* Adjust image width to fit container */
  }
  
  .phone-container-number {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .phone-container-text-area-heading {
    color: #598BD8; 
  }
  
  .phone-container-text-area-text {
    margin: 0;
    color: #fff;
    opacity: 0; /* Initially set opacity to 0 */
    transition: opacity 0.5s ease-in-out; /* Fade-in transition */
    padding-bottom: 5px;
  }
  
  .phone-container:hover .phone-container-text-area-text {
    opacity: 1; /* Fade-in effect */
  }
  

/* =============================================== */

/* ============== ICONLIST STYLES ================ */
.icon-list-grid-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 50vh;
  gap: 1em;
  padding: 0;
  margin-top: 1.5em;
}

.icon-list-grid-container :nth-child(2){
  margin-bottom: 3em;
}

}


/* For desktops and larger devices */
@media only screen and (min-width: 1324px) {
  /* Add styles specific to desktops and larger devices */
  #title {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  #ios-download-button img :hover{
    cursor: pointer;
  }

  /* ============= REVIEWS STYLES ==================*/
.review-area {
  height: 10vh;
  margin-top: 0;
}

.quote{
  padding: 1em 2em;
}


/* ===============================================*/

/* ============== ICONLIST STYLES ================ */
.icon-list-grid-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 40vh;
  gap: 1em;
  padding: 0;
  margin-top: 15em;
}

.icon-list-grid-container :nth-child(2){
  margin-bottom: 3em;
}

.icon-list-grid-container :nth-child(1){
  margin-top: .5em;
}
.icon-list-grid-container :nth-child(3){
  margin-top: .5em;
}

/*list-item-styles*/
.list-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}
.list-item-icon{

}

.list-item-text-area{

}
.list-item-heading{
  color: #598BD8;
}
.list-item-text{
  color: #F9FCFF;
}
/********************/



/*=========================================*/

  /* ============== Phone Container STYLES =========== */
.example-screens-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1em;
  max-width: 80%;
  margin: auto;
  height: 90vh;
}


}